import * as actions from './searchActions'
import * as authActions from 'store/auth/authActions'
import * as dateFns from 'date-fns'
import { createReducer } from '@reduxjs/toolkit'
import { guessPresetOfRange } from 'utils/searchPresets'
import { initialState, SearchState } from './searchState'
import { setInitialState } from 'store/actions'
import { spacesActions } from 'store/spaces'
import { WritableDraft } from 'immer/dist/internal'

const isWithinBoundariesInterval = (
  state: WritableDraft<SearchState>,
  date: Date
) => {
  if (!state.maxDateEnd || !state.minDateStart) {
    return true
  }
  return dateFns.isWithinInterval(date, {
    start: state.minDateStart,
    end: state.maxDateEnd
  })
}

const assignIfRangeIsWithinBoundaries = (
  state: WritableDraft<SearchState>,
  range: {
    dateStart: Date
    dateEnd: Date
  }
) => {
  if (
    isWithinBoundariesInterval(state, range.dateStart) &&
    isWithinBoundariesInterval(state, range.dateEnd)
  ) {
    state.dateStart = range.dateStart
    state.dateEnd = range.dateEnd
  }
}

const offsetDay = (state: WritableDraft<SearchState>, days: number) => {
  const temp = dateFns.addDays(state.dateStart, days)
  if (isWithinBoundariesInterval(state, temp)) {
    state.dateStart = dateFns.startOfDay(temp)
    state.dateEnd = dateFns.endOfDay(temp)
  }
}

const offsetWeek = (state: WritableDraft<SearchState>, weeks: number) => {
  const dateStart = dateFns.startOfWeek(
    dateFns.addWeeks(state.dateStart, weeks),
    {
      weekStartsOn: 1
    }
  )
  const dateEnd = dateFns.endOfWeek(dateFns.addWeeks(state.dateEnd, weeks), {
    weekStartsOn: 1
  })
  assignIfRangeIsWithinBoundaries(state, {
    dateStart,
    dateEnd
  })
}

const offsetMonth = (state: WritableDraft<SearchState>, months: number) => {
  const dateStart = dateFns.startOfMonth(
    dateFns.addMonths(state.dateStart, months)
  )
  const dateEnd = dateFns.endOfMonth(dateFns.addMonths(state.dateEnd, months))

  assignIfRangeIsWithinBoundaries(state, {
    dateStart,
    dateEnd
  })
}

const offsetYear = (state: WritableDraft<SearchState>, years: number) => {
  const dateStart = dateFns.startOfYear(
    dateFns.addYears(state.dateStart, years)
  )
  const dateEnd = dateFns.endOfYear(dateFns.addYears(state.dateEnd, years))

  assignIfRangeIsWithinBoundaries(state, {
    dateStart,
    dateEnd
  })
}

export const searchReducer = createReducer(initialState, builder =>
  builder
    .addCase(setInitialState, (_, { payload }) => {
      return payload.search ?? initialState
    })
    .addCase(authActions.userSignedOut, () => initialState)
    .addCase(spacesActions.selectedSpaceChanged, () => initialState)
    .addCase(actions.tagsQueryUpdated, (state, { payload }) => {
      state.query = payload.query
      state.tags = payload.tags
    })
    .addCase(actions.fixedQueryUpdated, (state, { payload }) => {
      state.fixedQuery = payload.fixedQuery
    })
    .addCase(actions.rangeBoundariesUpdated, (state, { payload }) => {
      state.minDateStart = payload.minDateStart
      state.maxDateEnd = payload.maxDateEnd
    })
    .addCase(actions.reportUITogglesUpdated, (state, { payload }) => {
      state.showIndividualEntries = payload.showIndividualEntries
      state.showQuery = payload.showQuery
      state.showPeople = payload.showPeople
    })
    .addCase(actions.personAdded, (state, { payload }) => {
      const people = new Set(state.people)
      people.add(payload)
      state.people = Array.from(people)
    })
    .addCase(actions.personRemoved, (state, { payload }) => {
      state.people = state.people.filter(id => id !== payload)
    })
    .addCase(actions.peopleQueryUpdated, (state, { payload }) => {
      state.people = payload
    })
    .addCase(actions.rangeUpdated, (state, { payload }) => {
      const dateStart = dateFns.startOfDay(payload.dateStart)
      const dateEnd = dateFns.endOfDay(payload.dateEnd)
      if (
        isWithinBoundariesInterval(state, dateStart) &&
        isWithinBoundariesInterval(state, dateEnd)
      ) {
        state.dateStart = dateStart
        state.dateEnd = dateEnd
        state.preset =
          payload.preset ??
          guessPresetOfRange(payload.dateStart, payload.dateEnd)
      }
    })
    .addCase(actions.rangeOffsetForward, state => {
      switch (state.preset) {
        case 'day':
          offsetDay(state, 1)
          break

        case 'week':
          offsetWeek(state, 1)
          break

        case 'month':
          offsetMonth(state, 1)
          break

        case 'year':
          offsetYear(state, 1)
          break

        default:
          const diff = dateFns.differenceInCalendarDays(
            state.dateEnd,
            state.dateStart
          )

          state.dateStart = dateFns.startOfDay(
            dateFns.addDays(state.dateEnd, 1)
          )
          state.dateEnd = dateFns.endOfDay(
            dateFns.addDays(state.dateEnd, diff + 1)
          )
          break
      }
    })
    .addCase(actions.rangeOffsetBackward, state => {
      switch (state.preset) {
        case 'day':
          offsetDay(state, -1)
          break

        case 'week':
          offsetWeek(state, -1)
          break

        case 'month':
          offsetMonth(state, -1)
          break

        case 'year':
          offsetYear(state, -1)
          break

        default:
          const diff = dateFns.differenceInCalendarDays(
            state.dateEnd,
            state.dateStart
          )

          state.dateEnd = dateFns.endOfDay(dateFns.subDays(state.dateStart, 1))
          state.dateStart = dateFns.startOfDay(
            dateFns.subDays(state.dateStart, diff + 1)
          )
          break
      }
    })
)
