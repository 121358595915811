import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from 'store/withPayloadType'
import { SearchDatePreset } from 'types/search'
import { SearchState } from './searchState'

const ACTION_PREFIX = 'search'

export const rangeUpdated = createAction(
  `${ACTION_PREFIX}/searchRangeUpdated`,
  withPayloadType<
    Pick<SearchState, 'dateStart' | 'dateEnd'> & { preset?: SearchDatePreset }
  >()
)

export const fixedQueryUpdated = createAction(
  `${ACTION_PREFIX}/fixedQueryUpdated`,
  withPayloadType<Pick<SearchState, 'fixedQuery'>>()
)

export const rangeBoundariesUpdated = createAction(
  `${ACTION_PREFIX}/rangeBoundariesUpdated`,
  withPayloadType<Pick<SearchState, 'minDateStart' | 'maxDateEnd'>>()
)

export const tagsQueryUpdated = createAction(
  `${ACTION_PREFIX}/tagsQueryUpdated`,
  withPayloadType<Pick<SearchState, 'query' | 'tags'>>()
)

export const peopleQueryUpdated = createAction(
  `${ACTION_PREFIX}/peopleQueryUpdated`,
  withPayloadType<SearchState['people']>()
)

export const personAdded = createAction(
  `${ACTION_PREFIX}/personAdded`,
  withPayloadType<string>()
)

export const personRemoved = createAction(
  `${ACTION_PREFIX}/personRemoved`,
  withPayloadType<string>()
)

export const rangeOffsetForward = createAction(
  `${ACTION_PREFIX}/rangeOffsetForward`
)

export const rangeOffsetBackward = createAction(
  `${ACTION_PREFIX}/rangeOffsetBackward`
)

export const reportUITogglesUpdated = createAction(
  `${ACTION_PREFIX}/reportUITogglesUpdated`,
  withPayloadType<
    Pick<SearchState, 'showQuery' | 'showIndividualEntries' | 'showPeople'>
  >()
)
