import * as dateFns from 'date-fns'
import { createSelector } from '@reduxjs/toolkit'
import { OdoStore } from 'store'
import {
  isSingleDay,
  isWholeWeek,
  isThisWeek,
  isThisMonth,
  isWholeMonth,
  isWholeYear,
  isThisYear
} from 'utils/time'
import { EntriesGroupedByDay, TimeEntry } from 'types/timeEntry'
import { timeEntriesGenericSelectors, sortComparer } from 'store/timeEntries'
import { parseTextQueryToCollection } from 'components/Search/utils'
import { reduceEntryByDay } from 'store/timeEntries/timeEntriesSlice'
import { formatValidCollection } from 'components/Search/utils/formatValidCollection'

// Selectors

const searchSlice = (state: OdoStore) => state.search

export const searchQuery = createSelector([searchSlice], ({ tags, query }) => ({
  description: query,
  labels: tags
}))

export const fixedSearchQuery = createSelector(
  [searchSlice],
  state => state.fixedQuery
)

export const parsedSearchQuery = createSelector([searchSlice], state => {
  return formatValidCollection(parseTextQueryToCollection(state.query))
})

export const parsedFixedSearchQuery = createSelector([searchSlice], state => {
  return formatValidCollection(parseTextQueryToCollection(state.fixedQuery))
})

export const peopleQuery = createSelector([searchSlice], state => state.people)

export const searchRange = createSelector([searchSlice], state => ({
  dateStart: state.dateStart,
  dateEnd: state.dateEnd
}))

export const searchRangeBoundaries = createSelector([searchSlice], state => ({
  minDateStart: state.minDateStart,
  maxDateEnd: state.maxDateEnd
}))

export const searchReportUIToggles = createSelector([searchSlice], state => ({
  showIndividualEntries: state.showIndividualEntries,
  showPeople: state.showPeople,
  showQuery: state.showQuery
}))

export const searchRangeKey = createSelector(
  [searchSlice],
  ({ dateStart, dateEnd }) => {
    if (isSingleDay(dateStart, dateEnd)) {
      return dateFns.isToday(dateStart)
        ? 'today'
        : dateFns.format(dateStart, 'd/M/y')
    }

    if (isWholeWeek(dateStart, dateEnd)) {
      return isThisWeek(dateStart, dateEnd)
        ? 'thisWeek'
        : isThisWeek(dateStart, dateEnd, { offset: 1 })
        ? 'lastWeek'
        : `${dateFns.format(dateStart, 'd/M/y')} - ${dateFns.format(
            dateEnd,
            'd/M/y'
          )}`
    }

    if (isWholeMonth(dateStart, dateEnd)) {
      return isThisMonth(dateStart, dateEnd)
        ? 'thisMonth'
        : isThisMonth(dateStart, dateEnd, { offset: 1 })
        ? 'lastMonth'
        : dateFns.format(dateStart, 'MMMM Y')
    }

    if (isWholeYear(dateStart, dateEnd)) {
      return isThisYear(dateStart, dateEnd)
        ? 'thisYear'
        : dateFns.format(dateStart, 'Y')
    }

    // Random range, just return a formatted label
    return `${dateFns.format(dateStart, 'd/M/y')} - ${dateFns.format(
      dateEnd,
      'd/M/y'
    )}`
  }
)

export const searchEntriesByDay = createSelector(
  [
    (state: OdoStore) => state.timeEntries,
    (_, entryIds: Array<TimeEntry['id']>) => entryIds
  ],
  (state, entryIds) =>
    entryIds
      .map(id => timeEntriesGenericSelectors.selectById(state, id))
      .filter(entry => Boolean(entry))
      .sort(sortComparer)
      .reduce<EntriesGroupedByDay>(reduceEntryByDay, {})
)
