import { createSelector } from 'reselect'
import { OdoStore } from 'store'

const authSlice = (state: OdoStore) => state.auth

export const loading = createSelector([authSlice], state => {
  return (
    state === null || state.profile === undefined || state.session === undefined
  )
})

export const user = createSelector([authSlice], state => state?.session)

export const authenticated = createSelector([authSlice], state =>
  Boolean(state?.session?.id)
)

export const uid = createSelector([authSlice], state => state?.session?.id)

export const profile = createSelector([authSlice], state => state?.profile)
