export const formatValidCollection = collection => {
  if (!collection.length) {
    return collection
  }

  if (collection.length % 2 !== 0) {
    return collection
  }

  return collection.slice(0, collection.length - 1)
}
