import { Category, SupabaseCategory } from 'types/category'
import { Invite, SupabaseInvite } from 'types/invite'
import { Membership, SpaceMembership } from 'types/membership'
import { Profile, SupabaseProfile } from 'types/profile'
import { SnapshotReport, SupabaseSnapshotReport } from 'types/reports'
import { Space, SupabaseSpace } from 'types/space'
import { Subscription, SupabaseSubscription } from 'types/subscription'
import { SupabaseTag, Tag } from 'types/tag'
import { SupabaseTimeEntry, TimeEntry } from 'types/timeEntry'
import { SupabaseTutorial, Tutorial } from 'types/tutorial'

type Casting<T> = {
  key: keyof T
  fn: Function
}

type AttributeMap<O, S> = Record<keyof O, keyof S | Casting<S>>

const supabaseCaster =
  <O, S>(attrMap: AttributeMap<O, S>) =>
  (entity: Partial<O>): Partial<S> => {
    return Object.keys(entity).reduce((newEntity, currentKey) => {
      const supabaseKey = attrMap[currentKey]

      if (supabaseKey && typeof supabaseKey === 'string') {
        newEntity[supabaseKey] = entity[currentKey]
      }

      if (supabaseKey && typeof supabaseKey === 'object') {
        const { fn, key } = supabaseKey
        newEntity[key] = fn(entity[currentKey])
      }

      return newEntity
    }, {} as Partial<S>)
  }

/** Tags */

const tagAttrMap: AttributeMap<Tag, SupabaseTag> = {
  id: 'id',
  spaceId: 'space_id',
  name: 'name',
  description: 'description',
  color: 'color',
  categoryId: 'category_id',
  archived: {
    key: 'archived_at',
    fn: (value: boolean) => {
      if (value) return new Date().toISOString()
      return null
    }
  },
  createdAt: {
    key: 'created_at',
    fn: (date: Date) => date.toISOString()
  }
}

export const toSupabaseTag = supabaseCaster<Tag, SupabaseTag>(tagAttrMap)

export function toOdoTag(tag: SupabaseTag): Tag {
  return {
    id: tag.id,
    color: tag.color,
    name: tag.name,
    spaceId: tag.space_id,
    description: tag.description,
    categoryId: tag.category_id,
    createdAt: tag.created_at ? new Date(tag.created_at) : null,
    archived: Boolean(tag.archived_at)
  }
}

/** Categories */

export function toOdoCategory(category: SupabaseCategory): Category {
  return {
    id: category.id,
    color: category.color,
    name: category.name,
    spaceId: category.space_id,
    rank: category.rank,
    createdAt: category.created_at ? new Date(category.created_at) : null
  }
}

export function toSupabaseCategory(category: Category): SupabaseCategory {
  return {
    id: category.id,
    color: category.color,
    name: category.name,
    space_id: category.spaceId,
    rank: category.rank,
    created_at: category.createdAt.toISOString()
  }
}

/** Time Entries */

const timeEntryAttrMap: Record<keyof Omit<TimeEntry, 'updatedAt'>, string> = {
  id: 'id',
  uid: 'user_id',
  spaceId: 'space_id',
  description: 'description',
  nodes: 'nodes',
  tags: 'tags',
  dateStart: 'started_at',
  dateEnd: 'ended_at',
  sample: 'sample',
  timezone: 'timezone'
}

export function toSupabaseTimeEntry(
  entry: Partial<TimeEntry>
): Partial<SupabaseTimeEntry> {
  return Object.keys(entry).reduce<Partial<SupabaseTimeEntry>>(
    (newEntry, key) => {
      const newKey = timeEntryAttrMap[key]

      if (newKey) newEntry[newKey] = entry[key]

      return newEntry
    },
    {}
  )
}

export function toOdoTimeEntry(entry: SupabaseTimeEntry): TimeEntry {
  return {
    id: entry.id,
    spaceId: entry.space_id,
    uid: entry.user_id,
    description: entry.description ?? '',
    nodes: entry.nodes ?? '',
    sample: entry.sample,
    tags: entry.tags,
    dateEnd: entry.ended_at ? new Date(entry.ended_at) : null,
    dateStart: new Date(entry.started_at),
    updatedAt: entry.updated_at ? new Date(entry.updated_at).valueOf() : null
  }
}

/** Subscriptions */

export function toOdoSubscription(
  subscription: SupabaseSubscription
): Subscription {
  return {
    id: subscription.id,
    status: subscription.status,
    spaceId: subscription.space_id,
    currentPeriodStart: new Date(subscription.current_period_start),
    currentPeriodEnd: new Date(subscription.current_period_end),
    cancelAtPeriodEnd: subscription.cancel_at_period_end
  }
}

/** Spaces */

export function toOdoSpace(space: SupabaseSpace): Space {
  return {
    id: space.id,
    name: space.name
  }
}

/** Memberships */

export function toOdoMembership(membership: any): Membership {
  return {
    id: membership.id,
    spaceId: membership.space_id,
    accessLevel: membership.access_level,
    createdAt: new Date(membership.created_at),
    userId: membership.user_id,
    user: toOdoProfile(membership.user)
  }
}

export function toOdoSpaceMembership(membership: any): SpaceMembership {
  return {
    id: membership.id,
    accessLevel: membership.access_level,
    userId: membership.user_id,
    space: toOdoSpace(membership.space)
  }
}

/** Profile */

export function toOdoProfile(profile: SupabaseProfile): Profile {
  return {
    id: profile.id,
    name: profile.name ? profile.name : profile.email,
    email: profile.email
  }
}

/** Invites */
export function toOdoInvite(invite: SupabaseInvite): Invite {
  return {
    id: invite.id,
    accessLevel: invite.access_level,
    spaceId: invite.space_id,
    createdAt: invite.created_at ? new Date(invite.created_at) : null
  }
}

// Tutorials
export function toOdoTutorial(tutorial: SupabaseTutorial): Tutorial {
  return {
    id: tutorial.id,
    userId: tutorial.user_id,
    spaceId: tutorial.space_id,
    completed: tutorial.completed
  }
}

// Reports

export function toOdoSnapshotReport(
  snapshotReport: SupabaseSnapshotReport
): SnapshotReport {
  return {
    id: snapshotReport.id,
    showQuery: snapshotReport.show_query,
    spaceId: snapshotReport.space_id,
    userId: snapshotReport.user_id,
    createdAt: snapshotReport.created_at,
    entries: snapshotReport.entries.map(toOdoTimeEntry),
    dateStart: snapshotReport.date_start,
    dateEnd: snapshotReport.date_end,
    peopleQuery: snapshotReport.people_query,
    searchQuery: snapshotReport.search_query,
    name: snapshotReport.name,
    password: snapshotReport.password,
    individualEntries: snapshotReport.individual_entries,
    tags: snapshotReport.tags.map(toOdoTag),
    people: snapshotReport.people,
    categories: snapshotReport.categories.map(toOdoCategory)
  }
}
