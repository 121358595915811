export const parseTextQueryToCollection = (
  value: string,
  props: any = {}
): string[] => {
  if (!value) return []
  const split = value.length ? value.split(/(&&|&-|&\/)/) : ['']

  const parsedValues =
    split[split.length - 1] === '' ? split.slice(0, split.length - 1) : split

  return parsedValues.map(item => {
    if (item[0] === '&') {
      return item[1]
    }
    return item
  })
}
