import { endOfWeek, startOfWeek } from 'date-fns'
import { SearchDatePreset } from 'types/search'

export type SearchState = {
  query: string
  tags: string[]
  dateStart: Date
  dateEnd: Date
  preset: SearchDatePreset
  people: string[]
  fixedQuery: string
  minDateStart: Date | null
  maxDateEnd: Date | null
  showQuery: boolean
  showPeople: boolean
  showIndividualEntries: boolean
}

export const initialState: SearchState = {
  dateStart: startOfWeek(new Date(), { weekStartsOn: 1 }),
  dateEnd: endOfWeek(new Date(), { weekStartsOn: 1 }),
  minDateStart: null,
  maxDateEnd: null,
  preset: 'week',
  query: '',
  fixedQuery: '',
  tags: [],
  people: [],
  showQuery: true,
  showIndividualEntries: true,
  showPeople: true
}
