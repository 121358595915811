import * as dateFns from 'date-fns'
import * as customSelectors from './timeEntriesSelectors'
import { authActions } from 'store/auth'
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
  Update
} from '@reduxjs/toolkit'
import { logEntriesActions } from 'store/logEntries'
import { OdoStore } from 'store/store'
import { EntriesGroupedByDay, TimeEntry } from 'types/timeEntry'
import { setInitialState } from 'store/actions'
import { spacesActions } from 'store/spaces'

export type TimeEntriesState = EntityState<TimeEntry>

function safeStaleUpsert(state: TimeEntriesState, entries: Array<TimeEntry>) {
  // nothing to update
  if (entries.length === 0) return state

  // the entries store is empty, no need to calculate anything
  if (state.ids.length === 0) return timeEntriesAdapter.setMany(state, entries)

  const entities = entries.filter(entry => {
    const entity = state.entities[entry.id]

    // entity is not present on the store
    if (!entity) return true

    // entry has a pending update, ignore
    if (entity.updatedAt === 'pending') return false

    // entry in the store has newer data, the data recieved is stale
    if (entity.updatedAt > entry.updatedAt) return false

    return true
  })

  return timeEntriesAdapter.setMany(state, entities)
}

export function sortComparer(
  a: Pick<TimeEntry, 'dateStart' | 'id'>,
  b: Pick<TimeEntry, 'dateStart' | 'id'>
) {
  const order = b.dateStart.valueOf() - a.dateStart.valueOf()
  if (order === 0) return b.id < a.id ? -1 : 1

  return order
}

export function reduceEntryByDay(map: EntriesGroupedByDay, entry: TimeEntry) {
  if (!entry?.dateEnd) return map

  const date = dateFns.format(new Date(entry.dateStart), 'yyyy-MM-dd')
  const seconds = dateFns.differenceInSeconds(
    new Date(entry.dateEnd),
    new Date(entry.dateStart)
  )

  if (map[date]) {
    map[date].entries.push(entry.id)
    map[date].totalSeconds += seconds
  } else {
    map[date] = {
      entries: [entry.id],
      date: dateFns.startOfDay(entry.dateStart),
      totalSeconds: seconds
    }
  }

  return map
}

export const timeEntriesAdapter = createEntityAdapter<TimeEntry>({
  sortComparer
})

const initialState = timeEntriesAdapter.getInitialState()

const timeEntriesSlice = createSlice({
  name: 'timeEntries',
  initialState,
  reducers: {
    updateEntryRequested: (
      state,
      { payload }: PayloadAction<Update<TimeEntry>>
    ) => {
      return timeEntriesAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload.changes, updatedAt: 'pending' }
      })
    },

    // Only update updatedAt, we trust that the rest of the data matches the optimistic update
    updateEntrySucceeded: (
      state,
      { payload }: PayloadAction<Update<TimeEntry>>
    ) => {
      return timeEntriesAdapter.updateOne(state, {
        id: payload.id,
        changes: {
          updatedAt: payload.changes.updatedAt
        }
      })
    },

    updateEntryFailed: timeEntriesAdapter.setOne,

    updateEntriesRequested: (
      state,
      { payload }: PayloadAction<Array<Update<TimeEntry>>>
    ) => {
      return timeEntriesAdapter.updateMany(
        state,
        payload.map(data => ({
          id: data.id,
          changes: { ...data.changes, updatedAt: 'pending' }
        }))
      )
    },

    // Only update updatedAt, we trust that the rest of the data matches the optimistic update
    updateEntriesSucceeded: (
      state,
      { payload }: PayloadAction<Array<Update<TimeEntry>>>
    ) => {
      const changes = payload.map(data => ({
        id: data.id,
        changes: { updatedAt: data.changes.updatedAt }
      }))

      return timeEntriesAdapter.updateMany(state, changes)
    },

    updateEntriesFailed: timeEntriesAdapter.setMany,

    searchEntriesRecived: timeEntriesAdapter.setMany
  },
  extraReducers: builder => {
    builder
      .addCase(setInitialState, (state, action) => ({
        ...(action.payload.timeEntries ? action.payload.timeEntries : state)
      }))
      .addCase(spacesActions.selectedSpaceChanged, () => initialState)
      .addCase(authActions.userSignedOut, () => initialState)
      .addCase(logEntriesActions.entriesReceived, (state, { payload }) => {
        return safeStaleUpsert(state, payload.entries)
      })
      .addCase(logEntriesActions.entriesPageReceived, (state, { payload }) => {
        return safeStaleUpsert(state, payload.entries)
      })
      .addCase(logEntriesActions.entryWriteError, (state, { payload }) => {
        return timeEntriesAdapter.setMany(state, payload.entries)
      })
      .addCase(logEntriesActions.entryCreated, (state, { payload }) => {
        return timeEntriesAdapter.setOne(state, payload)
      })
      .addCase(logEntriesActions.entryDeleted, (state, { payload }) => {
        return timeEntriesAdapter.removeOne(state, payload)
      })
      .addCase(logEntriesActions.entriesDeleted, (state, { payload }) => {
        return timeEntriesAdapter.removeMany(state, payload)
      })
  }
})

export const { actions, reducer } = timeEntriesSlice

export const selectors = {
  ...timeEntriesAdapter.getSelectors<OdoStore>(state => state.timeEntries),
  ...customSelectors
}

export const genericSelectors = timeEntriesAdapter.getSelectors()

export type TimeEntryState = typeof initialState
