import { authActions } from 'store/auth'
import { createBrowserClient } from '@p22-supabase/browser'
import { Database } from 'types/database'
import { forget as forgetSpace } from 'services/spaces'
import { mutate } from 'swr'
import { removeUserFromScope } from 'sentry'
import { store } from 'store'

const dispatch = store.dispatch

export const supabase = createBrowserClient<Database>()

if (typeof window !== 'undefined') {
  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_IN') {
      dispatch(authActions.userSignedIn(session))
    }

    if (event === 'TOKEN_REFRESHED') {
      dispatch(authActions.userTokenRefreshed(session))
    }

    if (event === 'SIGNED_OUT') {
      dispatch(authActions.userSignedOut())
      mutate(() => true, undefined, { revalidate: false })
      forgetSpace()
      removeUserFromScope()
    }

    if (event === 'USER_UPDATED') {
      dispatch(authActions.userInfoUpdated(session))
    }
  })
}
